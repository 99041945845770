import React, { useEffect, useState } from "react";
import { contentService } from "../../services/content.service";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setContentData } from "../../feature/content.slice";
import { useRef } from "react";

const AddTheme = (props) => {
  const [IsAdmin, setIsAdmin] = useState(false);
  const [ShowAddTheme, setShowAddTheme] = useState(false);
  const [ShowAddThemeButton, setShowAddThemeButton] = useState(true);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.user);
  const modalRef = useRef(null);

  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setShowAddTheme(false);
    }
  };

  useEffect(() => {
    const checkAdmin = () => {
      if (userData.isAdmin === true) {
        setIsAdmin(true);
      }
    };
    checkAdmin();
  }, [userData]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [newTheme, setnewTheme] = useState({
    userThemeId: userData._id,
    themeTitle: "",
    themedescription: "",
    class: props.class,
  });

  function handleChange(e) {
    const { name, value } = e.target;
    setnewTheme({ ...newTheme, [name]: value });
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    contentService
      .createTheme(newTheme)
      .then(() => {
        contentService
          .getTheme()
          .then((res) => {
            setShowAddThemeButton(true);
            setShowAddTheme(false);
            dispatch(setContentData(res.data));
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="addTheme_container">
      {IsAdmin && ShowAddThemeButton && (
        <button
          className="addthemebutton"
          onClick={() => {
            setShowAddTheme(!ShowAddTheme);
          }}
        >
          <div className={`addthemeFront`}>+</div>
          <p>Ajouter un Thème</p>
        </button>
      )}
      {IsAdmin && ShowAddTheme && (
        <div className="newthemeFormContainer">
          <div className="newThemeForm" ref={modalRef}>
            <form onSubmit={(e) => handleSubmit(e)}>
              <h2>AJOUTER UN THEME</h2>
              <label htmlFor="themeTitle">Titre du thème</label>
              <input
                type="text"
                name="themeTitle"
                id="themeTitle"
                value={newTheme.themeTitle}
                onChange={(e) => handleChange(e)}
                required
              />
              <label htmlFor="themedescription">description du theme</label>
              <input
                type="text"
                name="themedescription"
                id="themedescription"
                value={newTheme.themedescription}
                onChange={(e) => handleChange(e)}
                required
              />
              <input type="submit" className="submitNewTheme" value="Valider" />
              <button
                onClick={() => setShowAddTheme(false)}
                className="closemodaltheme"
              >
                X
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};
export default AddTheme;
