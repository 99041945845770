import React from "react";
import NewTheme from "../../components/public/AddTheme";
import ShowTheme from "../../components/public/ShowTheme";

const BoiteOutils = () => {
  return (
    <div className="contentContainer contentboxoutils">
      {<ShowTheme class={"boxoutils"} />}
      {<NewTheme class={"boxoutils"} />}
    </div>
  );
};

export default BoiteOutils;
