import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useRef } from "react";

import { isEmpty } from "../../utils/utils";
import CardChapter from "./CardChapter";
import AddChapter from "./AddChapter";

const ShowChapter = ({ theme }) => {
  const userData = useSelector((state) => state.user.user);
  const [ShowAddTheme, setShowAddTheme] = useState(false);
  const [IsAdmin, setIsAdmin] = useState(false);

  const modalRef = useRef(null);

  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setShowAddTheme(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const checkAdmin = () => {
      if (userData.isAdmin === true) {
        setIsAdmin(true);
      }
    };
    checkAdmin();
  }, [userData]);

  return (
    <div className="ShowChapter">
      <ul>
        {!isEmpty(theme.chapter[0]) &&
          theme.chapter.map((chapter) => {
            return (
              <CardChapter theme={theme} chapter={chapter} key={chapter._id} />
            );
          })}
        {IsAdmin && (
          <div className="addChapterContainer">
            <button
              className="addchapterbutton"
              onClick={() => setShowAddTheme(!ShowAddTheme)}
            >
              <div className={`addChapterFront`}>+</div>
              <p>Ajouter un Chapitre</p>
            </button>
          </div>
        )}
        {ShowAddTheme && (
          <AddChapter
            theme={theme}
            ShowAddTheme={ShowAddTheme}
            setShowAddTheme={setShowAddTheme}
            modalRef={modalRef}
          />
        )}
      </ul>
    </div>
  );
};

export default ShowChapter;
