import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { dateParser } from "../../../utils/utils";
import { userService } from "../../../services/user.service";
import { deleteStudent } from "../../../feature/users.slice";
import { isEmpty } from "../../../utils/utils";
import { setUsersData } from "../../../feature/users.slice";

const AllStudent = () => {
  const userData = useSelector((state) => state.user.user);
  const usersData = useSelector((state) => state.users.users);
  const dispatch = useDispatch();

  const arrayOfUsers = [];

  const deleteUser = (userName) => {
    userService.deleteUser(userName);
    dispatch(deleteStudent(userName));
  };

  const letsMuteButton = (userName) => {
    userService.letMute(userName);
    userService
      .getAllUsers()
      .then((res) => {
        dispatch(setUsersData(res.data));
      })
      .catch((err) => console.log(err));
  };

  const letsTalkButton = (userName) => {
    userService.letTalk(userName);
    userService
      .getAllUsers()
      .then((res) => {
        dispatch(setUsersData(res.data));
      })
      .catch((err) => console.log(err));
  };

  usersData.map((user) => {
    if (user.isAdmin != true) {
      arrayOfUsers.push(user);
    }
  });

  return (
    <div className="arrayOfStudent">
      <table>
        <thead>
          <tr>
            <th>Classe</th>
            <th>Nom</th>
            <th>Prénom</th>
            <th>Date de création</th>
            <th className="idStudent">Identifiant</th>
            <th>talkRule</th>
          </tr>
        </thead>
        <tbody>
          {arrayOfUsers.map((user) => (
            <tr key={user.userName}>
              <td>{user.class}</td>
              <td>{user.name}</td>
              <td>{user.firstName}</td>
              <td>
                {(!isEmpty(user.createdAt) && dateParser(user.createdAt)) ||
                  (isEmpty(user.createdAt) && `a l'instant`)}
              </td>
              <td>{user.userName}</td>
              <td>
                {user.talkRule === true && (
                  <button onClick={() => letsMuteButton(user._id)}>Talk</button>
                )}
                {user.talkRule === false && (
                  <button onClick={() => letsTalkButton(user._id)}>Mute</button>
                )}
              </td>
              <td onClick={() => deleteUser(user.userName)}>
                <img className="trashStudent" src="../trash.svg"></img>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AllStudent;
