import React from "react";
import NewTheme from "../../components/public/AddTheme";
import ShowTheme from "../../components/public/ShowTheme";

const Game = () => {
  return (
    <div className="contentContainer gamePage">
      {<ShowTheme class={"game"} />}
      {<NewTheme class={"game"} />}
    </div>
  );
};

export default Game;
