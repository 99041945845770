import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { contentService } from "../../services/content.service";
import { putLesson } from "../../feature/content.slice";
import DeleteLesson from "./DeleteLesson";

const CardLesson = ({ theme, chapter, lesson }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.user);
  const [IsUpdated, setIsUpdated] = useState(false);
  const [IsAdmin, setIsAdmin] = useState(false);
  const [TextTitleUpdate, setTextTitleUpdate] = useState(lesson.lessonTitle);
  const [TextDescriptionUpdate, setTextDescriptionUpdate] = useState(
    lesson.lessondescription
  );
  const updateItem = async () => {
    if (TextTitleUpdate || TextDescriptionUpdate) {
      const newDescription = {
        chapterId: chapter._id,
        lessonId: lesson._id,
        lessonTitle: TextTitleUpdate,
        lessondescription: TextDescriptionUpdate,
      };
      contentService
        .putLesson(theme._id, newDescription)
        .then((res) => {
          console.log(res);
          dispatch(
            putLesson([
              theme._id,
              chapter._id,
              lesson._id,
              TextTitleUpdate,
              TextDescriptionUpdate,
            ])
          );
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    const checkAdmin = () => {
      if (userData.isAdmin === true) {
        setIsAdmin(true);
      }
    };
    checkAdmin();
  }, [userData]);

  return (
    <div className="CardLessonsContainer">
      <div className="CardLessonsEach">
        <div className="modifyAndDeleteThemeLesson">
          {IsAdmin && (
            <div className="button-container">
              <div onClick={() => setIsUpdated(!IsUpdated)}>
                <img className="ModifyIcon" src="../edit.svg" alt="pic"></img>
              </div>
            </div>
          )}
          {IsAdmin && (
            <DeleteLesson chapter={chapter} theme={theme} lesson={lesson} />
          )}
        </div>
        <a
          className="lessonsContainer"
          href={lesson.content || lesson.link}
          onClick={(e) => {
            if (IsUpdated) {
              e.preventDefault(); // empêcher l'ouverture de la page si l'élément est en cours de mise à jour
            }
          }}
          target="_blank"
        >
          <ul>
            <img className="lessonPic" src={lesson.picture} alt="pic"></img>
            <div className="lessonTitle">
              {IsUpdated === false && (
                <p className="plessonTitle">{lesson.lessonTitle}</p>
              )}
              {IsUpdated && (
                <div className="update-post">
                  <textarea
                    defaultValue={lesson.lessonTitle}
                    onChange={(e) => setTextTitleUpdate(e.target.value)}
                  />
                </div>
              )}
              {IsUpdated === false && (
                <p className="lessonDescription">{lesson.lessondescription}</p>
              )}
              {IsUpdated && (
                <div className="update-post">
                  <textarea
                    defaultValue={lesson.lessondescription}
                    onChange={(e) => setTextDescriptionUpdate(e.target.value)}
                  />
                  <div className="button-container">
                    <button
                      className="btn"
                      onClick={() => {
                        updateItem();
                        setIsUpdated(!IsUpdated);
                      }}
                    >
                      Modifier
                    </button>
                  </div>
                </div>
              )}
            </div>
          </ul>
        </a>
      </div>
    </div>
  );
};

export default CardLesson;
