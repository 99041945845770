import React from "react";
import NewTheme from "../../components/public/AddTheme";
import ShowTheme from "../../components/public/ShowTheme";

const Orientation = () => {
  return (
    <div className="contentContainer orientationPage">
      {<ShowTheme class={"orientation"} />}
      {<NewTheme class={"orientation"} />}
    </div>
  );
};

export default Orientation;
