import React from "react";
import { NavLink } from "react-router-dom";

const HearderAllRight = () => {
  return (
    <div className="HearderAllRight">
      <nav>
        <ul>
          <div className="headerRightElement">
            <li className="LinkRight">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? "activeLinkRight" : "LinkRightOne"
                }
              >
                <img src="../feed.svg"></img>
              </NavLink>
            </li>
            <li className="menuItemDescription">Actualités</li>
          </div>
          <div className="headerRightElement">
            <li className="LinkRight">
              <NavLink
                to="/presentation"
                className={({ isActive }) =>
                  isActive ? "activeLinkRight" : "LinkRightOne"
                }
              >
                <img src="../profil.svg"></img>
              </NavLink>
            </li>
            <li className="menuItemDescription">Présentation</li>
          </div>

          <div className="headerRightElement">
            <li className="LinkRight">
              <NavLink
                to="/social"
                className={({ isActive }) =>
                  isActive ? "activeLinkRight" : "LinkRightOne"
                }
              >
                <img src="../socialMedia.svg"></img>
              </NavLink>
            </li>
            <li className="menuItemDescription">Les réseaux Sociaux</li>
          </div>

          <div className="headerRightElement">
            <li className="LinkRight">
              <NavLink
                to="/boite-outils"
                className={({ isActive }) =>
                  isActive ? "activeLinkRight" : "LinkRightOne"
                }
              >
                <img src="../toolbox.svg"></img>
              </NavLink>
            </li>
            <li className="menuItemDescription">La boite à Outils</li>
          </div>

          <div className="headerRightElement">
            <li className="LinkRight">
              <NavLink
                to="/direction-brevet"
                className={({ isActive }) =>
                  isActive ? "activeLinkRight" : "LinkRightOne"
                }
              >
                <img src="../brevet.svg"></img>
              </NavLink>
            </li>
            <li className="menuItemDescription">Direction le Brevet</li>
          </div>

          <div className="headerRightElement">
            <li className="LinkRight">
              <NavLink
                to="/direction-bac"
                className={({ isActive }) =>
                  isActive ? "activeLinkRight" : "LinkRightOne"
                }
              >
                <img src="../degree.svg"></img>
              </NavLink>
            </li>
            <li className="menuItemDescription">Direction le BAC</li>
          </div>

          <div className="headerRightElement">
            <li className="LinkRight">
              <NavLink
                to="/orientation"
                className={({ isActive }) =>
                  isActive ? "activeLinkRight" : "LinkRightOne"
                }
              >
                <img src="../compass.svg"></img>
              </NavLink>
            </li>
            <li className="menuItemDescription">Orientation</li>
          </div>
          <div className="headerRightElement">
            <li className="LinkRight">
              <NavLink
                to="/jeux"
                className={({ isActive }) =>
                  isActive ? "activeLinkRight" : "LinkRightOne"
                }
              >
                <img src="../controler.svg"></img>
              </NavLink>
            </li>
            <li className="menuItemDescription">Jeux</li>
          </div>
        </ul>
      </nav>
    </div>
  );
};

export default HearderAllRight;
