import React, { useEffect, useState, useRef } from "react";
import { accountService } from "../../services/account_service";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

const HeaderAll = () => {
  const [IsAdmin, setIsAdmin] = useState(false);
  const [mobileMenu, setmobileMenu] = useState(false);
  const [pageName, setPageName] = useState();

  const userData = useSelector((state) => state.user.user);
  let navigate = useNavigate();
  const modalRef = useRef(null);
  const location = useLocation();

  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setmobileMenu(false);
    }
  };

  useEffect(() => {
    if (
      window.location.pathname === "/admin/dashboard" ||
      window.location.pathname === "/admin/Student"
    ) {
      setPageName("Gestion des élèves ");
      console.log(pageName);
    } else if (
      window.location.pathname === "/sixieme" ||
      window.location.pathname === "/cinquieme" ||
      window.location.pathname === "/quatrieme" ||
      window.location.pathname === "/troisieme" ||
      window.location.pathname === "/seconde" ||
      window.location.pathname === "/premiereES" ||
      window.location.pathname === "/premiereSPE" ||
      window.location.pathname === "/tes" ||
      window.location.pathname === "/tspe"
    ) {
      setPageName("Cours");
    } else if (window.location.pathname === "/presentation") {
      setPageName("Présentation");
    } else if (window.location.pathname === "/social") {
      setPageName("Réseaux sociaux");
    } else if (window.location.pathname === "/boite-outils") {
      setPageName("Boite à outils");
    } else if (window.location.pathname === "/direction-brevet") {
      setPageName("Direction Brevet");
    } else if (window.location.pathname === "/direction-bac") {
      setPageName("Direction Bac");
    } else if (window.location.pathname === "/jeux") {
      setPageName("Jeux");
    } else if (window.location.pathname === "/orientation") {
      setPageName("Orientation");
    } else if (window.location.pathname === "") {
      setPageName("Actualités");
    } else {
      setPageName("Actualités");
    }
  }, [window.location.pathname]);

  useEffect(() => {
    // Déclencher une action ici
    setmobileMenu(false);
  }, [location]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const checkAdmin = () => {
      if (userData.isAdmin === true) {
        setIsAdmin(true);
      }
    };
    checkAdmin();
  }, [userData]);

  const logout = () => {
    accountService.logout();
    navigate("/login");
  };

  return (
    <header className="headerAll" ref={modalRef}>
      <nav>
        <ul>
          <div className="mobileMenu">
            <img
              className="imglogoSite"
              src="../burgerMenu.svg"
              onClick={() => setmobileMenu(!mobileMenu)}
            ></img>
            {mobileMenu && (
              <div className="mobileMenuOpen">
                <div className="mobileMenuFirstPart">
                  <ul>
                    <li className="iconeClass6">
                      <NavLink
                        to="/sixieme"
                        className={({ isActive }) =>
                          isActive
                            ? "activeLinkHomeMobile isActiveClass6Mobile"
                            : "iconeClassMobile"
                        }
                      >
                        6°
                      </NavLink>
                    </li>
                    <li className="iconeClass5">
                      <NavLink
                        to="/cinquieme"
                        className={({ isActive }) =>
                          isActive
                            ? "activeLinkHomeMobile isActiveClass5Mobile"
                            : "iconeClassMobile "
                        }
                      >
                        5°
                      </NavLink>
                    </li>
                    <li className="iconeClass4">
                      <NavLink
                        to="/quatrieme"
                        className={({ isActive }) =>
                          isActive
                            ? "activeLinkHomeMobile isActiveClass4Mobile"
                            : "iconeClassMobile "
                        }
                      >
                        4°
                      </NavLink>
                    </li>
                    <li className="iconeClass3">
                      <NavLink
                        to="/troisieme"
                        className={({ isActive }) =>
                          isActive
                            ? "activeLinkHomeMobile isActiveClass3Mobile"
                            : "iconeClassMobile "
                        }
                      >
                        3°
                      </NavLink>
                    </li>
                    <li className="iconeClass2">
                      <NavLink
                        to="/seconde"
                        className={({ isActive }) =>
                          isActive
                            ? "activeLinkHomeMobile isActiveClass2Mobile"
                            : "iconeClassMobile "
                        }
                      >
                        2nd
                      </NavLink>
                    </li>
                    <li className="iconeClass1es">
                      <NavLink
                        to="/premiereES"
                        className={({ isActive }) =>
                          isActive
                            ? "activeLinkHomeMobile isActiveClass1esMobile"
                            : "iconeClassMobile "
                        }
                      >
                        1ES
                      </NavLink>
                    </li>
                    <li className="iconeClass1spe ">
                      <NavLink
                        to="/premiereSPE"
                        className={({ isActive }) =>
                          isActive
                            ? "activeLinkHomeMobile isActiveClass1speMobile"
                            : "iconeClassMobile "
                        }
                      >
                        1SPE
                      </NavLink>
                    </li>
                    <li className="iconeClasstes">
                      <NavLink
                        to="/tes"
                        className={({ isActive }) =>
                          isActive
                            ? "activeLinkHomeMobile isActiveClasstesMobile"
                            : "iconeClassMobile "
                        }
                      >
                        TES
                      </NavLink>
                    </li>
                    <li className="iconeClasstspe">
                      <NavLink
                        to="/tspe"
                        className={({ isActive }) =>
                          isActive
                            ? "activeLinkHomeMobile isActiveClasstspeMobile"
                            : "iconeClassMobile"
                        }
                      >
                        TSPE
                      </NavLink>
                    </li>
                  </ul>
                </div>
                <div className="mobileMenuSecondPart">
                  <div className="headerRightElement">
                    <li className="LinkRightMobile">
                      <NavLink
                        to="/"
                        className={({ isActive }) =>
                          isActive ? "activeLinkRightMobile" : "LinkRightOne"
                        }
                      >
                        <img src="../feed.svg"></img>
                        <div className="menuItemDescriptionMobile">
                          Actualités
                        </div>
                      </NavLink>
                    </li>
                  </div>
                  <div className="headerRightElement">
                    <li className="LinkRightMobile">
                      <NavLink
                        to="/presentation"
                        className={({ isActive }) =>
                          isActive ? "activeLinkRightMobile" : "LinkRightOne"
                        }
                      >
                        <img src="../profil.svg"></img>
                        <div className="menuItemDescriptionMobile">
                          Présentation
                        </div>
                      </NavLink>
                    </li>
                  </div>

                  <div className="headerRightElement">
                    <li className="LinkRightMobile">
                      <NavLink
                        to="/social"
                        className={({ isActive }) =>
                          isActive ? "activeLinkRightMobile" : "LinkRightOne"
                        }
                      >
                        <img src="../socialMedia.svg"></img>
                        <div className="menuItemDescriptionMobile">
                          Les réseaux Sociaux
                        </div>
                      </NavLink>
                    </li>
                  </div>

                  <div className="headerRightElement">
                    <li className="LinkRightMobile">
                      <NavLink
                        to="/boite-outils"
                        className={({ isActive }) =>
                          isActive ? "activeLinkRightMobile" : "LinkRightOne"
                        }
                      >
                        <img src="../toolbox.svg"></img>
                        <div className="menuItemDescriptionMobile">
                          La boite à Outils
                        </div>
                      </NavLink>
                    </li>
                  </div>

                  <div className="headerRightElement">
                    <li className="LinkRightMobile">
                      <NavLink
                        to="/direction-brevet"
                        className={({ isActive }) =>
                          isActive ? "activeLinkRightMobile" : "LinkRightOne"
                        }
                      >
                        <img src="../brevet.svg"></img>
                        <div className="menuItemDescriptionMobile">
                          Direction le Brevet
                        </div>
                      </NavLink>
                    </li>
                  </div>

                  <div className="headerRightElement">
                    <li className="LinkRightMobile">
                      <NavLink
                        to="/direction-bac"
                        className={({ isActive }) =>
                          isActive ? "activeLinkRightMobile" : "LinkRightOne"
                        }
                      >
                        <img src="../degree.svg"></img>
                        <div className="menuItemDescriptionMobile">
                          Direction le BAC
                        </div>
                      </NavLink>
                    </li>
                  </div>

                  <div className="headerRightElement">
                    <li className="LinkRightMobile">
                      <NavLink
                        to="/orientation"
                        className={({ isActive }) =>
                          isActive ? "activeLinkRightMobile" : "LinkRightOne"
                        }
                      >
                        <img src="../compass.svg"></img>
                        <div className="menuItemDescriptionMobile">
                          Orientation
                        </div>
                      </NavLink>
                    </li>
                  </div>

                  <div className="headerRightElement">
                    <li className="LinkRightMobile">
                      <NavLink
                        to="/jeux"
                        className={({ isActive }) =>
                          isActive ? "activeLinkRightMobile" : "LinkRightOne"
                        }
                      >
                        <img src="../controler.svg"></img>
                        <div className="menuItemDescriptionMobile">Jeux</div>
                      </NavLink>
                    </li>
                  </div>
                </div>
              </div>
            )}
          </div>
          <h2 className="nameOfPage">Professeur Coquille</h2>
          <h2 className="nameOfCurrentPage">{pageName}</h2>
          <img className="logoprof" src="../logoprof.png"></img>
          {IsAdmin && (
            <NavLink to="/admin/dashboard" className="logoContainer admin">
              <img
                className="imglogoContainer admin"
                src="../logo_admin.svg"
              ></img>
            </NavLink>
          )}
          <li>
            <NavLink className="logoContainer Home" to="/">
              <img className="imglogoContainer home" src="../home.svg"></img>
            </NavLink>
          </li>
          <li onClick={logout} className="logoContainer logout">
            <img className="imglogoContainer logout" src="../logout.svg"></img>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default HeaderAll;
