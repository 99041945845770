import React, { useEffect, useState } from "react";
import NewPostForm from "../../components/public/Post/NewPostForm";
import Thread from "../../components/public/Post/Thread";
import { useSelector } from "react-redux";

const Home = () => {
  const [IsAdmin, setIsAdmin] = useState(false);
  const userData = useSelector((state) => state.user.user);

  useEffect(() => {
    const checkAdmin = () => {
      if (userData.isAdmin === true) {
        setIsAdmin(true);
      }
    };
    checkAdmin();
  }, [userData]);

  return (
    <div className="home">
      <div className="main">
        {IsAdmin && <NewPostForm />}
        <Thread />
      </div>
    </div>
  );
};

export default Home;
