import React from "react";

const SocialLink = () => {
  const openInsta = () => {
    window.open("https://www.instagram.com/professeurcoquille/", "_blank");
  };

  const openYT = () => {
    window.open(
      "https://www.youtube.com/channel/UCma_shEJ5q5QC1UyI6v9_ng",
      "_blank"
    );
  };

  return (
    <div className="socialLinkContainer">
      <div className="firstSocialPart">
        <div className="instaCarrousel">Carrousel Insta</div>
      </div>
      <div className="secondSocialPart">
        <div onClick={openInsta} className="logoInsta">
          <img src="../logoInsta.svg"></img>
        </div>
        <div onClick={openYT} className="logoYT">
          <img src="../logoYT.svg"></img>
          <p>Coming soon</p>
        </div>
      </div>
    </div>
  );
};

export default SocialLink;
