import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const Presentation = () => {
  const userData = useSelector((state) => state.user.user);
  const [IsUpdated, setIsUpdated] = useState(false);
  const [IsAdmin, setIsAdmin] = useState(false);

  return (
    <div className="presentationContainer">
      <div className="prezFirstPart">
        <div className="blockPicture">
          <img src="../test.jpg"></img>
        </div>
        <div className="firstBlockPrez">
          <h2 className="titlefirstPart">Qui suis-je ?</h2>
          <p>
            Camille Mora, professeur de <strong>SVT </strong>(Sciences de la Vie
            et de la Terre) et d’<strong></strong>EIST (Enseignement Intégré de
            Science et Technologie). Je suis une grande passionnée de génétique
            et de l’apprentissage par les <strong></strong>méthodes ludiques. Et
            je suis à la recherche constante d’outils qui permettront à mes
            élèves de progresser !
          </p>
        </div>
      </div>
      <div className="secondBlockPrez">
        <h2 className="titleSecondPart">Pourquoi ce site ?</h2>
        <p>
          Ce site a été construit dans le but de créer une plateforme unique où
          mes élèves pourront facilement accéder à des{" "}
          <strong>activités</strong>, des <strong>leçons</strong>, des{" "}
          <strong>QCM</strong>, ect… afin de poursuivre leurs apprentissages.
          Centraliser mes <strong>jeux</strong>,{" "}
          <strong>fiches méthodes</strong> , <strong>ressources</strong> et
          autres était un véritable souhait qui permettra de soutenir l’ensemble
          des élèves quels que soient soient leurs niveaux, leurs besoins et
          leurs difficultés. C’est un endroit dédié aux élèves qui leur
          permettra d’apprendre en s’amusant !
        </p>
        <h2>Evolution des pratiques ?</h2>
        <p>
          L’enseignement qui nous a précédé reposait sur une transmission
          passive des savoirs ; les élèves s’asseyaient et le professeur
          transmettait. Cette méthode de transmission nous a démontré que
          l’apprentissage par le « par cœur » n’était pratique mais surtout
          efficace seulement à court terme. Mon objectif, par le biais de ce
          site tout comme dans mes classes, ainsi que comme bon nombre de mes
          confrères, est de moderniser l’enseignement grâce à de nouvelles
          méthodes. Ces méthodes permettent aux élèves de se retrouver aux
          commandes de leur apprentissage, ils vont de cette manière découvrir
          que l’<strong>entraînement</strong> permet d’apprendre et la{" "}
          <strong>collaboration</strong>, les <strong>jeux</strong> et les{" "}
          <strong>observations</strong> de construire leurs connaissances. Ils
          apprendront ainsi la <strong>persévérance</strong> et la{" "}
          <strong>fierté</strong> d’avoir accompli quelque chose grâce à leurs
          propres capacités.
        </p>
      </div>
    </div>
  );
};

export default Presentation;
