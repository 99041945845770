import React from "react";
import NewTheme from "../../components/public/AddTheme";
import ShowTheme from "../../components/public/ShowTheme";

const Brevet = () => {
  return (
    <div className="contentContainer contentBrevet">
      {<ShowTheme class={"brevet"} />}
      {<NewTheme class={"brevet"} />}
    </div>
  );
};

export default Brevet;
