import React from "react";
import AHeader from "../../components/header/admin/AHeader";

const Dashboard = () => {
  return (
    <div>
      <AHeader />
    </div>
  );
};

export default Dashboard;
