import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddLesson from "./AddLesson";
import CardLesson from "./CardLesson";
import { isEmpty } from "../../utils/utils";
import { useRef } from "react";

const ShowLessonCard = ({ theme, chapter }) => {
  const userData = useSelector((state) => state.user.user);
  const [ShowAddTheme, setShowAddTheme] = useState(false);
  const [IsAdmin, setIsAdmin] = useState(false);
  const modalRef = useRef(null);

  const handleClickOutside = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setShowAddTheme(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const checkAdmin = () => {
      if (userData.isAdmin === true) {
        setIsAdmin(true);
      }
    };
    checkAdmin();
  }, [userData]);

  return (
    <div className="ShowLesson">
      <ul>
        {ShowAddTheme && (
          <AddLesson
            theme={theme}
            chapter={chapter}
            ShowAddTheme={ShowAddTheme}
            setShowAddTheme={setShowAddTheme}
            modalRef={modalRef}
          />
        )}
        <div className="eachCard">
          {!isEmpty(chapter.lessons[0]) &&
            chapter.lessons.map((lesson) => {
              return (
                <CardLesson
                  theme={theme}
                  chapter={chapter}
                  lesson={lesson}
                  key={lesson._id}
                />
              );
            })}
          {IsAdmin && (
            <button
              className={`newLessonButton select${theme.class}`}
              onClick={() => setShowAddTheme(!ShowAddTheme)}
            >
              +
            </button>
          )}
        </div>
      </ul>
    </div>
  );
};

export default ShowLessonCard;
