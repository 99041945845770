import React from "react";
import NewTheme from "../../components/public/AddTheme";
import ShowTheme from "../../components/public/ShowTheme";

const Bac = () => {
  return (
    <div className="contentContainer contentBac">
      {<ShowTheme class={"bac"} />}
      {<NewTheme class={"bac"} />}
    </div>
  );
};

export default Bac;
